import { memo, useCallback } from "react";
import PropTypes from "prop-types";
import "./CenteredLogoBlock.scss";
import RelativeLink from "app/pages/.shared/RelativeLink";
import Paragraphs from "app/pages/.shared/Paragraphs";
import { useLocation } from "react-router-dom";
import { useAnalytics } from "app/utils/analytics/useAnalytics";

const CenteredLogoBlock = ({ title, text, image, color, cta = {} }) => {
	const location = useLocation();
	const { track } = useAnalytics();

	const handleCTAClick = useCallback(() => {
		track("centered-logo-block-cta-click", {
			actualUrl: location.pathname,
			redirectUrl: cta.link,
		});
	}, [cta.link, location.pathname]);
	return (
		<div className="centered-logo-block" data-testid="centered-logo-block">
			{image && (
				<div className="centered-logo-block__picto">
					<img src={image} />
				</div>
			)}

			{title && (
				<div
					className="centered-logo-block__title"
					style={{ color: color }}
					data-testid="centered-logo-block-title"
				>
					<Paragraphs paragraphs={title} enableHTMLFormatting />
				</div>
			)}

			{text && (
				<div
					className="centered-logo-block__content"
					data-testid="centered-logo-block-content"
				>
					<Paragraphs paragraphs={text} enableHTMLFormatting />
				</div>
			)}

			{cta.link && (
				<div className="centered-logo-block__cta">
					<RelativeLink
						variant="primary"
						to={{ pathname: cta.link }}
						style={{ backgroundColor: cta.bgColor }}
						target={cta?.openInNewWindow ? "_blank" : "_self"}
						onClick={handleCTAClick}
					>
						{cta.label}
					</RelativeLink>
				</div>
			)}
		</div>
	);
};

CenteredLogoBlock.propTypes = {
	title: PropTypes.string,
	text: PropTypes.string,
	image: PropTypes.string,
	color: PropTypes.string,
	cta: PropTypes.object,
};

export default memo(CenteredLogoBlock);
