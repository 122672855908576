import { useContext, useCallback, useState, useMemo } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { productShape } from "app/utils/propTypes";
import Button from "app/pages/.shared/form/Button";
import Paragraphs from "app/pages/.shared/Paragraphs";
import { ProductsCarousel } from "app/pages/.shared/ProductsCarousel";
import AppGlobalsContext from "app/AppGlobalsContext";
import AuthModal from "app/pages/Auth/AuthModal/AuthModal";
import AuthLayerMobile from "app/pages/Auth/AuthLayerMobile/AuthLayerMobile";
import { RESOLUTION } from "app/pages/.shared/responsive/responsiveReducer";
import ProductCard from "app/pages/.shared/ProductCard/ProductCard";
import RelativeLink from "app/pages/.shared/RelativeLink";
import BackgroundGradient from "app/pages/.shared/BackgroundGradient/BackgroundGradient";
import { BG_GRADIENT_ANIMATION } from "app/constants";
import { FormattedMessage } from "react-intl";
import { useLocation } from "react-router-dom";
import ChevronRight from "app/pages/.shared/static/icons/ChevronRight";
import "./Membership.scss";
import useBlockViewportScroll from "app/pages/.shared/useBlockViewportScroll";
import { useAnalytics } from "app/utils/analytics/useAnalytics";
import Modal from "react-modal";
import { TYPOGRAPHY_VARIANTS } from "app/pages/.shared/Typography/Typography";
import Typography from "app/pages/.shared/Typography/Typography";

const style = {
	overlay: {
		zIndex: 6, // 6 to be above the main header and the marketing banner
	},
};

const MembershipBlock = ({
	products = [],
	advantages = [],
	title,
	userIsConnected,
	userIsIdentified,
	cta = {},
	protectedPageTexts = {},
	membershipTexts = {},
}) => {
	const { resolution, shop } = useContext(AppGlobalsContext);
	const [isAuthLayerVisible, setAuthLayerVisible] = useState(false);
	const isMobile = resolution === RESOLUTION.SMALL || resolution === RESOLUTION.MEDIUM;
	const { pathname } = useLocation();
	const isMerchPage = pathname.includes("/merch");
	const isLandingPages = pathname.includes("/landing-pages");
	const { track } = useAnalytics();
	const shouldShowMembershipBlock = userIsConnected || userIsIdentified;
	useBlockViewportScroll(isAuthLayerVisible);

	const onAuthSuccess = useCallback(() => {
		setAuthLayerVisible(false);
	}, []);

	const handleViewAllSalesClick = useCallback(() => {
		track("membership_view_all_sales_click");
	}, []);

	const handleSignupClick = useCallback(() => {
		track("membership_signup_click");
		setAuthLayerVisible(true);
	}, []);

	const { title: landingTitle, description: landingDescription } = membershipTexts;

	const authMarketingBlock = useMemo(
		() => (
			<div className="auth-modal__marketing">
				<div className="auth-modal__marketing-content">
					<Typography
						variant={TYPOGRAPHY_VARIANTS.XL3}
						variantMD={TYPOGRAPHY_VARIANTS.XL4}
						isBold
					>
						<div className="auth-modal__marketing-title">
							{landingTitle && landingTitle.length > 0 && (
								<Paragraphs paragraphs={landingTitle} enableHTMLFormatting />
							)}
						</div>
					</Typography>
					{landingDescription && landingDescription.length > 0 && (
						<div className="auth-modal__marketing-description">
							<Paragraphs paragraphs={landingDescription} enableHTMLFormatting />
						</div>
					)}
				</div>
			</div>
		),
		[protectedPageTexts, membershipTexts]
	);

	return (
		<div
			className={classNames({
				membership: true,
				"membership--connected": shouldShowMembershipBlock,
				"membership--disconnected": !shouldShowMembershipBlock,
			})}
			data-testid="membership-block"
		>
			<BackgroundGradient animation={BG_GRADIENT_ANIMATION.NONE} />
			<div className="membership__container">
				<div className="membership__landing">
					{title && (
						<div
							className={classNames({
								membership__title: true,
								"membership__title--connected": shouldShowMembershipBlock,
								"membership__title--disconnected": !shouldShowMembershipBlock,
							})}
						>
							<Typography variant={TYPOGRAPHY_VARIANTS.XL4} isBold>
								<Paragraphs paragraphs={title} enableHTMLFormatting />
							</Typography>
						</div>
					)}
					{shouldShowMembershipBlock && !isMobile && (
						<div className="membership__all-sales">
							<RelativeLink
								to={{ pathname: "/listing" }}
								onClick={handleViewAllSalesClick}
							>
								<FormattedMessage id="membership.all.our.sales" />
								<ChevronRight width={16} height={16} />
							</RelativeLink>
						</div>
					)}

					{!shouldShowMembershipBlock && (
						<>
							{advantages?.length > 0 && (
								<div className="membership__advantages">
									{advantages.map((advantage, index) => (
										<div key={index} className="membership__advantage-item">
											<div className="membership__advantage-picto">
												<img
													src={advantage.picto}
													alt={advantage?.advantageText}
												/>
											</div>
											<Typography
												variant={TYPOGRAPHY_VARIANTS.REGULAR}
												isBold
											>
												<div className="membership__advantage-text">
													{advantage?.advantageText}
												</div>
											</Typography>
										</div>
									))}
								</div>
							)}
							<div className="membership__cta">
								<Button variant="primary" onClick={handleSignupClick}>
									{cta?.label}
								</Button>
							</div>
						</>
					)}
				</div>

				{products?.length > 0 && isMobile ? (
					<div className="membership__offers">
						<ProductsCarousel products={products} />
					</div>
				) : (
					<div
						className={classNames({
							membership__offers: true,
							"membership__offers--merch": isMerchPage,
							"membership__offers--landingPages": isLandingPages,
						})}
					>
						{products?.map((product, index) => {
							return (
								<div key={product?.id} data-testid={`product-${product?.uri}`}>
									<ProductCard product={product} position={index} />
								</div>
							);
						})}
					</div>
				)}

				{isMobile && shouldShowMembershipBlock && (
					<div className="membership__all-sales">
						<RelativeLink
							to={{ pathname: "/listing" }}
							variant="secondary"
							onClick={handleViewAllSalesClick}
						>
							<FormattedMessage id="membership.all.our.sales" />
							<ChevronRight width={16} height={16} />
						</RelativeLink>
					</div>
				)}

				{isAuthLayerVisible && isMobile ? (
					<Modal style={style} isOpen={isAuthLayerVisible}>
						<AuthLayerMobile
							onAuthSuccess={onAuthSuccess}
							resolution={resolution}
							showAtolAbta={shop === "en-GB"}
							onClose={onAuthSuccess}
							signinMarketingBlock={authMarketingBlock}
							signupMarketingBlock={authMarketingBlock}
						/>
					</Modal>
				) : (
					<AuthModal
						isOpen={isAuthLayerVisible}
						onAuthSuccess={onAuthSuccess}
						showAtolAbta={shop === "en-GB"}
						onClose={onAuthSuccess}
						signinMarketingBlock={authMarketingBlock}
						signupMarketingBlock={authMarketingBlock}
					/>
				)}
			</div>
		</div>
	);
};

MembershipBlock.propTypes = {
	advantages: PropTypes.array,
	products: PropTypes.arrayOf(productShape),
	title: PropTypes.string,
	userIsConnected: PropTypes.bool,
	userIsIdentified: PropTypes.bool,
	cta: PropTypes.object,
	protectedPageTexts: PropTypes.object,
	membershipTexts: PropTypes.object,
};

export default MembershipBlock;
