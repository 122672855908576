import * as React from "react";
import { Fragment, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import AppGlobalsContext from "app/AppGlobalsContext";
import { BRANDS, SDP_SEARCH_MARKETING_BLOCKS, STATIC_PAGE_BLOCKS } from "app/constants";
import { RESOLUTION } from "app/pages/.shared/responsive/responsiveReducer";
import { FIRST_MERCHANDISING_POSITION } from "src/@brand/appConstants";
import "./DynamicBlocksRenderer.scss";
import sortBy from "lodash/sortBy";
import CenteredLogoBlock from "app/pages/.shared/DynamicBlocks/blocks/CenteredLogoBlock/CenteredLogoBlock";
import PhotoArticles from "app/pages/.shared/DynamicBlocks/blocks/PhotoArticles/PhotoArticles";
import SmartDPReasons from "app/pages/.shared/DynamicBlocks/blocks/SmartDPReasons/SmartDPReasons";
import MerchCarouselBlock from "app/pages/.shared/DynamicBlocks/blocks/MerchCarousel/MerchCarouselBlock";
import SmartDPBanner from "app/pages/.shared/DynamicBlocks/blocks/SmartDPBanner/SmartDPBanner";
import SmartDPReInsurance from "app/pages/.shared/DynamicBlocks/blocks/SmartDPReInsurance/SmartDPReInsurance";
import OneMerchBanner from "app/pages/.shared/DynamicBlocks/blocks/OneMerchBanner/OneMerchBanner";
import ProductsCarouselBlock from "app/pages/.shared/DynamicBlocks/blocks/ProductsCarousel/ProductsCarouselBlock";
import MembershipContainer from "app/pages/.shared/DynamicBlocks/blocks/Membership/MembershipBlockContainer";
import { FormattedMessage } from "react-intl";
import IconInfo from "app/pages/.shared/static/icons/IconInfo";
import FloatingButton from "app/pages/.shared/Popover/FloatingButton";
import { paymentTypeShape } from "app/utils/propTypes";
import PaymentModesTooltipContainer from "app/pages/Booking/Payment/PaymentData/PaymentDataModeFirst/PaymentModes/PaymentModesTooltipContainer";
import Typography, { TYPOGRAPHY_VARIANTS } from "app/pages/.shared/Typography/Typography";
import ProductListBlockContainer from "app/pages/.shared/DynamicBlocks/blocks/ProductListBlock/ProductListBlockContainer";
import ContentImageCarrousel from "app/pages/Merchandising/Media/ContentImageCarrousel";
import CustomFormattedMessage from "app/utils/CustomFormattedMessage";
import StepsDescriptionBlock from "app/pages/.shared/StepsDescription/StepsDescriptionBlock";

const DynamicBlocksRenderer = ({
	blocks = [],
	dividerComponent,
	fetchMerchandisings = () => {},
	fetchProducts = () => {},
	products = [],
	merchandisings = [],
	userIsConnected,
	availablePaymentTerms = [],
	availablePaymentMethods = [],
	onlinePercentage,
	shouldShowReasonToBook = true,
}) => {
	const { resolution, shop, productsVisibility, partnerCode, brand } = useContext(
		AppGlobalsContext
	);
	const isMobile = resolution === RESOLUTION.SMALL || resolution === RESOLUTION.MEDIUM;

	// ajouter ici la liste des blocks necessitant la liste des merchandisings
	// pour ne faire qu'un seul appel
	const shouldFetchMerchandisings = blocks.some(
		block =>
			block._type === SDP_SEARCH_MARKETING_BLOCKS.MERCH_CAROUSSEL ||
			block._type === SDP_SEARCH_MARKETING_BLOCKS.ONE_MERCH_BANNER
	);

	// ajouter ici la liste des blocks necessitant des produits de la listing
	// pour ne faire qu'un seul appel
	const shouldFetchProducts = blocks.some(
		block => block._type === SDP_SEARCH_MARKETING_BLOCKS.PP_CAROUSEL
	);

	useEffect(() => {
		// this useEffect cannot be placed in container because some blocks (merch carousel etc ...) will disappear after login or logout
		if (shouldFetchMerchandisings) {
			fetchMerchandisings({ shop, productsVisibility, partnerCode });
		}
	}, [shouldFetchMerchandisings, shop, productsVisibility, partnerCode]);

	useEffect(() => {
		if (shouldFetchProducts) {
			fetchProducts({ shop, productsVisibility, partnerCode });
		}
	}, [shouldFetchProducts, shop, productsVisibility, partnerCode, blocks]);

	return (
		<div className="dynamic-blocks-renderer">
			{blocks.map((block, index) => {
				let blockComponent;

				switch (block._type) {
					case STATIC_PAGE_BLOCKS.CENTERED_LOGO_BLOCK:
						blockComponent = (
							<div className="dynamic-blocks-renderer__item">
								<CenteredLogoBlock
									title={block.title}
									text={block.text}
									image={block.image}
									color={block.color?.hex}
									cta={block.cta}
								/>
							</div>
						);
						break;
					case STATIC_PAGE_BLOCKS.PHOTO_ARTICLES:
						blockComponent = (
							<div className="dynamic-blocks-renderer__item">
								<PhotoArticles
									title={block.title}
									articles={block.articles}
									isMobile={isMobile}
								/>
							</div>
						);
						break;
					case SDP_SEARCH_MARKETING_BLOCKS.REASONS_TO_BOOK:
						blockComponent = shouldShowReasonToBook ? (
							<div className="dynamic-blocks-renderer__item">
								<SmartDPReasons />
							</div>
						) : (
							false
						);
						break;
					case SDP_SEARCH_MARKETING_BLOCKS.STEPS_DESCRIPTION:
						blockComponent = (
							<div className="dynamic-blocks-renderer__item">
								<StepsDescriptionBlock
									title={block.title}
									stepsDescriptionList={block.steps}
								/>
							</div>
						);
						break;
					case SDP_SEARCH_MARKETING_BLOCKS.MERCH_CAROUSSEL: {
						// on ajoute temporairement ce code pour garder le même comportement que sur
						// le carousel XX

						if (merchandisings.length >= 3) {
							const merchandisingsPosition0 = merchandisings.filter(
								merchandising =>
									merchandising.position === FIRST_MERCHANDISING_POSITION &&
									block?.category?.name?.includes(
										merchandising.displayOptions?.category?.name
									)
							);

							const sortedsMerchandisingsPosition0 = sortBy(
								merchandisingsPosition0,
								"headerIndex"
							);

							const isBrandOV = brand === BRANDS.OV;

							const maxMerchCount = isBrandOV && !isMobile ? 3 : 4;

							const merchandisingListToDisplay = sortedsMerchandisingsPosition0.slice(
								0,
								maxMerchCount
							);

							blockComponent = (
								<div className="dynamic-blocks-renderer__item">
									<MerchCarouselBlock
										introduction={block.intro}
										merchandisingTitle={block.merchTitle}
										merchandisings={merchandisingListToDisplay}
										title={block.title}
										customItem={
											isBrandOV &&
											!isMobile && (
												<div
													style={{
														display: "flex",
														alignItems: "center",
														position: "absolute",
														top: 0,
														right: 0,
														height: "100%",
													}}
												>
													<span
														style={{
															position: "absolute",
															top: "12px",
															fontSize: "8px",
															textTransform: "uppercase",
															color: "#333",
														}}
													>
														<FormattedMessage id="general.advertising.label" />
													</span>
													<div
														id="oan_ora_1_300x250_voyages.hp"
														style={{
															width: "300px",
															height: "250px",
														}}
													/>
												</div>
											)
										}
									/>
								</div>
							);
						}
						break;
					}
					case SDP_SEARCH_MARKETING_BLOCKS.BANNER:
						blockComponent = (
							<div className="dynamic-blocks-renderer__item">
								<SmartDPBanner
									resolution={resolution}
									imageDesktop={block.imageDesktop}
									imageDesktopOverlay={block.imageDesktopOverlay}
									imageMobileOverlay={block.imageMobileOverlay}
									imageDesktopThumbnail={block.imageDesktopThumbnail}
									imageMobile={block.imageMobile}
									imageMobileThumbnail={block.imageMobileThumbnail}
									url={block.link}
									isImmersive={block.immersive}
									openInNewWindow={block.openInNewWindow}
								/>
							</div>
						);
						break;

					case SDP_SEARCH_MARKETING_BLOCKS.REINSURANCE:
						if (
							!block.hideIfLogged ||
							(Boolean(block.hideIfLogged) && !userIsConnected)
						) {
							blockComponent = (
								<div className="dynamic-blocks-renderer__item">
									<SmartDPReInsurance
										resolution={resolution}
										title={block.title}
										introduction={block.intro}
										image={block.image}
										thumbnail={block.thumbnail}
										text={block.text}
										openInNewWindow={block.cta?.openInNewWindow}
										buttonLabel={block.cta?.label}
										url={block.cta?.link}
										photoPosition={block?.photoPosition}
									/>
								</div>
							);
						}
						break;

					case SDP_SEARCH_MARKETING_BLOCKS.ONE_MERCH_BANNER: {
						const oneMerchBannerMerchandising = merchandisings.find(
							merchandising =>
								merchandising.displayOptions?.category?.name ===
								block.category?.name
						);

						if (!isEmpty(oneMerchBannerMerchandising)) {
							blockComponent = (
								<div className="dynamic-blocks-renderer__item">
									<OneMerchBanner
										resolution={resolution}
										title={block.title}
										merchandising={oneMerchBannerMerchandising}
										photoPosition={block?.photoPosition}
										merchandisings={merchandisings}
									/>
								</div>
							);
						}
						break;
					}
					case SDP_SEARCH_MARKETING_BLOCKS.PP_CAROUSEL:
						blockComponent = (
							<div className="dynamic-blocks-renderer__item">
								<ProductsCarouselBlock
									products={products}
									introduction={block.intro}
									title={block.title}
									cta={block.cta}
								/>
							</div>
						);
						break;
					case SDP_SEARCH_MARKETING_BLOCKS.PRODUCT_LIST:
						blockComponent = (
							<div className="dynamic-blocks-renderer__item dynamic-blocks-renderer__item--full-width">
								<ProductListBlockContainer
									merchCode={block?.merchandisingCode}
									shortVersion={block.shortVersion}
									displayFilters={block?.displayFilters}
									displayCatalogProductsOnly={block.displayCatalogProductsOnly}
								/>
							</div>
						);
						break;

					case SDP_SEARCH_MARKETING_BLOCKS.PAYMENT_DESCRIPTION_BLOCK:
						blockComponent =
							availablePaymentTerms.length > 1 ? (
								<div className="dynamic-blocks-renderer__item">
									<div className="payment-description">
										<Typography
											variant={TYPOGRAPHY_VARIANTS.LARGE}
											variantMD={TYPOGRAPHY_VARIANTS.XL2}
											isBold
											className="payment-description__content"
										>
											<CustomFormattedMessage
												message={block?.description[0]}
												values={{
													depositPercentage: (
														<div className="payment-description__terms-item">
															<FormattedMessage
																id="payment.terms.description.payment.percentage"
																values={{
																	percentage: onlinePercentage,
																}}
															/>
														</div>
													),

													paymentTerms: availablePaymentTerms
														.filter(term => term !== 1)
														.map(
															(term, index) =>
																term !== 1 && (
																	<React.Fragment key={index}>
																		{index !== 0 && (
																			<FormattedMessage id="payment.terms.description.link.label" />
																		)}
																		<div className="payment-description__terms-item">
																			{term}x
																		</div>
																	</React.Fragment>
																)
														),
												}}
												className="payment-description__terms"
												children={
													<FloatingButton
														position="bottom"
														floatingContent={
															<PaymentModesTooltipContainer
																paymentModes={
																	availablePaymentMethods
																}
															/>
														}
														referenceComponent={
															<IconInfo width={15} height={15} />
														}
													/>
												}
											/>
										</Typography>
									</div>
								</div>
							) : null;
						break;
					case SDP_SEARCH_MARKETING_BLOCKS.MEMBERSHIP:
						blockComponent = (
							<div className="dynamic-blocks-renderer__item dynamic-blocks-renderer__item--full-width">
								<MembershipContainer />
							</div>
						);
						break;

					case SDP_SEARCH_MARKETING_BLOCKS.CONTENT_IMAGE_CARROUSEL:
						blockComponent = (
							<div className="dynamic-blocks-renderer__item dynamic-blocks-renderer__item--full-width">
								<ContentImageCarrousel
									articles={block?.media?.articles}
									resolution={resolution}
									title={block?.title}
									description={block?.description}
								/>
							</div>
						);
						break;
					default: {
						blockComponent = null;
					}
				}

				return (
					blockComponent && (
						<Fragment key={index}>
							{index > 0 && dividerComponent}
							{blockComponent}
						</Fragment>
					)
				);
			})}
		</div>
	);
};
DynamicBlocksRenderer.propTypes = {
	blocks: PropTypes.array,
	dividerComponent: PropTypes.node,
	fetchMerchandisings: PropTypes.func,
	fetchProducts: PropTypes.func,
	products: PropTypes.array,
	merchandisings: PropTypes.array,
	userIsConnected: PropTypes.bool,
	availablePaymentTerms: PropTypes.arrayOf(paymentTypeShape),
	availablePaymentMethods: PropTypes.array,
	shouldShowReasonToBook: PropTypes.bool,
	onlinePercentage: PropTypes.number,
};

export default DynamicBlocksRenderer;
