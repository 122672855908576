import PropTypes from "prop-types";
import "./DotNotification.scss";

const DotNotification = ({ text }) => {
	return (
		<div className="dot-notification" data-testid="dot-notification">
			{text}
		</div>
	);
};

DotNotification.propTypes = {
	text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default DotNotification;
