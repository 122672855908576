import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { fetchMerchandisings } from "app/pages/Merchandising/merchandisingActionCreators";
import { getCategorizedVisibleMerchandisings } from "app/pages/Merchandising/merchandisingSelector";
import { isAuthenticated } from "app/pages/Auth/authSelectors";
import DynamicBlocksRenderer from "./DynamicBlocksRenderer";
import { paymentTypeShape } from "app/utils/propTypes";
import {
	getAvailablePaymentTerms,
	getPartnerDepositPercentage,
	shouldShowReasonToBook,
} from "app/reducers/partnerSelector";
import { getAvailablePaymentMethods } from "app/pages/Booking/Payment/paymentSelector";
import { fetchProducts } from "app/pages/Listing/productActionCreators";
import { getOngoingProducts } from "app/pages/Listing/productsSelector";

const DynamicBlocksRendererContainer = props => {
	return <DynamicBlocksRenderer {...props} />;
};

DynamicBlocksRendererContainer.propTypes = {
	blocks: PropTypes.array,
	dividerComponent: PropTypes.node,
	fetchMerchandisings: PropTypes.func,
	fetchProducts: PropTypes.func,
	products: PropTypes.array,
	merchandisings: PropTypes.array,
	userIsConnected: PropTypes.bool,
	availablePaymentTerms: PropTypes.arrayOf(paymentTypeShape),
	availablePaymentMethods: PropTypes.array,
	shouldShowReasonToBook: PropTypes.bool,
};

const mapStateToProps = state => {
	return {
		userIsConnected: isAuthenticated(state),
		products: getOngoingProducts(state),
		merchandisings: getCategorizedVisibleMerchandisings(state),
		availablePaymentTerms: getAvailablePaymentTerms(state),
		availablePaymentMethods: getAvailablePaymentMethods(state),
		shouldShowReasonToBook: shouldShowReasonToBook(state),
		onlinePercentage: getPartnerDepositPercentage(state),
	};
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators({ fetchMerchandisings, fetchProducts }, dispatch);
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(DynamicBlocksRendererContainer);
