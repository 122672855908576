import { memo } from "react";
import "./SmartDPReasons.scss";
import ReasonToBookBlockContainer from "app/pages/.shared/ReasonsToBook/ReasonToBookBlock/ReasonToBookBlockContainer";

const SmartDPReasons = () => {
	return (
		<div className="sdp-reasons" data-testid="sdp-reasons">
			<ReasonToBookBlockContainer />
		</div>
	);
};

export default memo(SmartDPReasons);
