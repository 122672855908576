import PropTypes from "prop-types";
import { memo, useEffect, useState } from "react";
import Paragraphs from "app/pages/.shared/Paragraphs";
import Typography, { TYPOGRAPHY_VARIANTS } from "app/pages/.shared/Typography/Typography";
import { ProductsCarousel } from "app/pages/.shared/ProductsCarousel";
import { FormattedMessage } from "react-intl";
import RelativeLink from "app/pages/.shared/RelativeLink";
import IconRight from "app/pages/.shared/static/icons/IconRight";
import ProductCard from "app/pages/.shared/ProductCard/ProductCard";
import ProductList from "app/pages/Listing/ProductList/ProductList";
import ProductListBlockFilter from "app/pages/.shared/DynamicBlocks/blocks/ProductListBlock/ProductListBlockFilter";
import classNames from "classnames";
import "./ProductListBlock.scss";

const ProductListBlock = ({
	title,
	shortVersion,
	shouldHideProductPriceBanner,
	products = [],
	redirectUrl,
	displayFilters = false,
	isMobile,
}) => {
	const [productsFiltered, setProductsFiltered] = useState(products);
	const productListBLockTitleClassNames = classNames({
		"product-list-block__title": true,
		"product-list-block__title--with-filter": displayFilters,
	});

	// sans ce useEffect, productsFiltered est tout le temps vide
	useEffect(() => {
		setProductsFiltered(products);
	}, [products]);

	if (products.length === 0) {
		return false;
	}

	let productsNode = false;

	if (isMobile) {
		if (shortVersion) {
			productsNode = (
				<ProductsCarousel
					products={productsFiltered}
					hidePriceBanner={shouldHideProductPriceBanner}
					noSwiping={!isMobile}
				/>
			);
		} else {
			productsNode = (
				<ProductList
					products={productsFiltered}
					hidePriceBanner={shouldHideProductPriceBanner}
					hideMembershipBlock={true}
				/>
			);
		}
	} else if (shortVersion) {
		productsNode = (
			<div className="product-list-block__products">
				{productsFiltered.map((product, index) => {
					return (
						<div key={product.id} data-testid={`product-${product.uri}`}>
							<ProductCard
								product={product}
								position={index}
								hidePriceBanner={shouldHideProductPriceBanner}
							/>
						</div>
					);
				})}
			</div>
		);
	} else {
		productsNode = (
			<ProductList
				products={productsFiltered}
				hidePriceBanner={shouldHideProductPriceBanner}
				hideMembershipBlock={true}
			/>
		);
	}

	return (
		<div className="product-list-block" data-testid="product-list-block">
			<div className="product-list-block__container">
				<div className={productListBLockTitleClassNames}>
					{title && (
						<Typography
							variant={TYPOGRAPHY_VARIANTS.XL3}
							variantMD={TYPOGRAPHY_VARIANTS.XL4}
							isBold
							component="h2"
						>
							<Paragraphs paragraphs={title} enableHTMLFormatting />
						</Typography>
					)}

					{!isMobile && shortVersion && (
						<div className="product-list-block__view-all-offers-link">
							<RelativeLink variant="naked" to={redirectUrl}>
								<Typography variant={TYPOGRAPHY_VARIANTS.LARGE} isBold>
									<FormattedMessage id="products.carousel.view.all.offers.label" />
								</Typography>
								<IconRight width={16} height={16} />
							</RelativeLink>
						</div>
					)}
				</div>
			</div>
			{displayFilters && !shortVersion && (
				<ProductListBlockFilter products={products} filterProduct={setProductsFiltered} />
			)}
			<div className="product-list-block__container">
				{productsNode}

				{isMobile && shortVersion && (
					<div className="product-list-block__view-all-offers-button">
						<RelativeLink variant="secondary" to={redirectUrl}>
							<Typography variant={TYPOGRAPHY_VARIANTS.LARGE} isBold>
								<FormattedMessage id="products.carousel.view.all.offers.label" />
							</Typography>
							<IconRight width={16} height={16} />
						</RelativeLink>
					</div>
				)}
			</div>
		</div>
	);
};

ProductListBlock.propTypes = {
	products: PropTypes.array,
	title: PropTypes.string,
	redirectUrl: PropTypes.string,
	shouldHideProductPriceBanner: PropTypes.bool,
	shortVersion: PropTypes.bool,
	displayFilters: PropTypes.bool,
	isMobile: PropTypes.bool,
};

export default memo(ProductListBlock);
