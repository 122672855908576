import { memo } from "react";
import PropTypes from "prop-types";
import PhotoArticle from "./PhotoArticle";
import { htmlSanitize } from "app/utils/sanitize";
import "./PhotoArticles.scss";
import Typography, { TYPOGRAPHY_VARIANTS } from "app/pages/.shared/Typography/Typography";

const PhotoArticles = ({ title, articles = [], isMobile }) => {
	return (
		<div className="photo-articles">
			<Typography
				variant={TYPOGRAPHY_VARIANTS.XL3}
				variantMD={TYPOGRAPHY_VARIANTS.XL4}
				isBold
				className="photo-articles__title"
				component="h2"
			>
				<span dangerouslySetInnerHTML={{ __html: htmlSanitize(title) }} />
			</Typography>
			<div className="photo-articles__list">
				{articles.map((article, index) => {
					return (
						<div
							key={index}
							className="photo-articles__item"
							data-testid="photo-articles-item"
						>
							<PhotoArticle {...article} isMobile={isMobile} />
						</div>
					);
				})}
			</div>
		</div>
	);
};

PhotoArticles.propTypes = {
	title: PropTypes.string,
	articles: PropTypes.array,
	isMobile: PropTypes.bool,
};

export default memo(PhotoArticles);
