import { memo, useCallback } from "react";
import PropTypes from "prop-types";
import Paragraphs from "app/pages/.shared/Paragraphs";
import "./ProductsCarouselBlock.scss";
import { messagePropType, productShape } from "app/utils/propTypes";
import { ProductsCarousel } from "app/pages/.shared/ProductsCarousel";
import RelativeLink from "app/pages/.shared/RelativeLink";
import { useAnalytics } from "app/utils/analytics/useAnalytics";
import { useLocation } from "react-router-dom";
import Typography, { TYPOGRAPHY_VARIANTS } from "app/pages/.shared/Typography/Typography";

const ProductsCarouselBlock = ({ title, cta = {}, onItemClick = () => {}, products = [] }) => {
	const { track } = useAnalytics();
	const location = useLocation();

	const handleCTAClick = useCallback(
		() => {
			track("products_carousel_block_cta_click", {
				ctaLabel: cta.label,
				actualUrl: location.pathname,
				redirectUrl: "/listing",
			});
		},
		[cta.label],
		location.pathname
	);

	return (
		<div className="pp-carousel-block" data-testid="pp-carousel-block">
			{title && (
				<Typography
					variant={TYPOGRAPHY_VARIANTS.XL3}
					variantMD={TYPOGRAPHY_VARIANTS.XL4}
					isBold
					className="pp-carousel-block__title"
					component="h2"
				>
					<Paragraphs paragraphs={title} enableHTMLFormatting />
				</Typography>
			)}
			<div className="pp-carousel-block__products">
				<ProductsCarousel onItemClick={onItemClick} products={products} />
			</div>
			<RelativeLink
				to={{ pathname: "/listing" }}
				onClick={handleCTAClick}
				className="pp-carousel-block__cta"
				variant="secondary"
				data-testid="pp-carousel-block-cta"
			>
				{cta.label}
			</RelativeLink>
		</div>
	);
};

ProductsCarouselBlock.propTypes = {
	products: PropTypes.arrayOf(productShape),
	title: messagePropType,
	cta: PropTypes.object,
	onItemClick: PropTypes.func,
};

export default memo(ProductsCarouselBlock);
