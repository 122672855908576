import { memo, useContext, useEffect, useState } from "react";
import { fetchMerchandisingApi } from "app/pages/Merchandising/merchandisingActionCreators";
import PropTypes from "prop-types";
import AppGlobalsContext from "app/AppGlobalsContext";
import { computedProductDates, sortProducts } from "app/utils/utils";
import ProductListBlock from "app/pages/.shared/DynamicBlocks/blocks/ProductListBlock/ProductListBlock";
import { fetchProductsApi } from "app/pages/Listing/productActionCreators";
import { useIntl } from "react-intl";
import { RESOLUTION } from "app/pages/.shared/responsive/responsiveReducer";
import { stringify } from "qs";
import { shouldHideMerchProductPriceBanner } from "app/pages/Merchandising/merchandisingSelector";

const MAX_PRODUCTS = 3;

const ProductListBlockContainer = props => {
	const {
		merchCode,
		shortVersion,
		displayCatalogProductsOnly,
		shortVersionFeaturedProductUris = [],
	} = props;
	const [products, setProducts] = useState([]);

	const [shouldHideProductPriceBanner, setHideProductPriceBanner] = useState(false);

	const intl = useIntl();
	const [title, setTitle] = useState(
		intl.formatMessage({ id: "products.carousel.listing.title" })
	);
	const { shop, partnerCode, productsVisibility, resolution } = useContext(AppGlobalsContext);
	const isMobile = resolution === RESOLUTION.SMALL || resolution === RESOLUTION.MEDIUM;

	const redirectUrl = merchCode
		? {
				pathname: "/merch",
				search: `?${stringify({ code: merchCode })}`,
		  }
		: {
				pathname: "/listing",
		  };

	useEffect(() => {
		if (merchCode) {
			fetchMerchandisingApi({ code: merchCode, shop, productsVisibility, partnerCode }).then(
				response => {
					setTitle(response?.data?.merchandising?.label);

					let merchProductList = computedProductDates(response?.data?.products);
					let merchCatalogProductList = response?.data?.catalogProducts || [];
					merchCatalogProductList = computedProductDates(merchCatalogProductList);
					let finalProductList = merchProductList.concat(merchCatalogProductList);

					if (displayCatalogProductsOnly) {
						finalProductList = finalProductList.filter(product => !product.isFlashsale);
					} else {
						finalProductList = finalProductList.filter(product => product.isFlashsale);
					}

					setProducts(finalProductList);
					setHideProductPriceBanner(
						shouldHideMerchProductPriceBanner(response?.data?.merchandising)
					);
				}
			);
		} else {
			fetchProductsApi({ shop, productsVisibility, partnerCode })
				.then(response => {
					let newProductList = computedProductDates(response?.data?.products);

					setProducts(newProductList);
				})
				.catch(error => {
					// eslint-disable-next-line no-console
					console.error(error);
				});
		}
	}, [
		merchCode,
		shop,
		productsVisibility,
		partnerCode,
		shortVersion,
		displayCatalogProductsOnly,
	]);

	let finalProductList = [...products];

	if (shortVersion) {
		if (shortVersionFeaturedProductUris?.length > 0) {
			const shortVersionFeaturedProducts = products.filter(product =>
				shortVersionFeaturedProductUris.includes(product.uri)
			);

			if (shortVersionFeaturedProducts?.length > 0) {
				finalProductList = sortProducts({
					products: shortVersionFeaturedProducts,
				}).slice(0, MAX_PRODUCTS);
			} else {
				finalProductList = products.slice(0, MAX_PRODUCTS);
			}
		} else {
			finalProductList = products.slice(0, MAX_PRODUCTS);
		}
	}

	return (
		<ProductListBlock
			{...props}
			products={finalProductList}
			title={title}
			redirectUrl={redirectUrl}
			isMobile={isMobile}
			shouldHideProductPriceBanner={shouldHideProductPriceBanner}
		/>
	);
};

ProductListBlockContainer.propTypes = {
	merchCode: PropTypes.string,
	shortVersion: PropTypes.bool,
	shortVersionFeaturedProductUris: PropTypes.array,
	displayCatalogProductsOnly: PropTypes.bool,
	displayFilters: PropTypes.bool,
};

export default memo(ProductListBlockContainer);
